<template>
  <div class="result">
    <div>
      <div class="d-header">
        <van-icon @click="$router.go(-1)" name="arrow-left" />
        <span>智能检测</span>
      </div>
      <div>
        <div class="result-details-count">
          80分<i class="result-details-count-i"></i>
        </div>
        <div>
          <div>本次检测用时03分54秒</div>
          <div>
            <div>
              <div>2345</div>
              <div>总检测设备</div>
            </div>
            <div>
              <div>2个</div>
              <div>异常设备</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <van-cell v-for="(item, index) in list" :key="index">
          <div class="result-list">
            <van-image width="40" height="40" :src="item.img" />
            <div>
              <div>
                <div>L1-10056智能灯掉线</div>
                <div>三年级2班｜五号教学楼三楼</div>
              </div>
              <div>
                <div>今天 12:30</div>
                <div :class="{ active: index % 2 === 0 }">异常</div>
              </div>
            </div>
          </div>
        </van-cell>
      </van-list>
    </div>
  </div>
</template>
<script>
import { List, Image as VanImage, Cell } from "vant";
export default {
  components: {
    [List.name]: List,
    [Cell.name]: Cell,
    [VanImage.name]: VanImage,
  },
  data() {
    return {
      loading: false,
      finished: true,
      list: [
        {
          img: require("@/assets/灯泡.svg"),
        },
        {
          img: require("@/assets/窗帘.svg"),
        },
        {
          img: require("@/assets/空调.svg"),
        },
        {
          img: require("@/assets/自动门.svg"),
        },
        {
          img: require("@/assets/室内检测仪器.svg"),
        },
        {
          img: require("@/assets/麦克风.svg"),
        },
      ],
    };
  },
  methods: {
    onLoad() {},
  },
  computed: {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
@import "../../../style/public.scss";
.result {
  width: 100%;
  height: 100%;
  .van-cell {
    width: 343px;
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 16px;
    box-shadow: 0px 0px 10px #ebebeb;
    border-radius: 5px;
    height: 76px;
    display: flex;
    flex-flow: row;
    padding: 12px 12px 12px 6px;
  }
  &-list {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    > div:first-child {
      margin-right: 6px;
    }
    > div:last-child {
      flex: 1;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      > div:first-child {
        > div:first-child {
          font-size: 15px;
          font-weight: 400;
          color: $titleColor;
        }
        > div:last-child {
          font-size: 12px;
          font-weight: 400;
          color: $subTitleColor;
        }
      }
      > div:last-child {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        align-items: flex-end;
        > div:first-child {
          font-size: 12px;
          font-weight: 400;
          color: $defocus;
        }
        > div:last-child {
          font-size: 12px;
          font-weight: 400;
          color: $green;
        }
        > div:last-child.active {
          color: $red;
        }
      }
    }
  }
  > div:first-child {
    width: 100%;
    height: 210px;
    background: $themeColor;
    > div:last-child {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-around;
      align-items: center;
      margin-top: 38px;
      > div:first-child {
        width: 84px;
        height: 84px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        font-size: 24px;
        font-weight: 400;
        color: $themeColor;
        background: rgba(255, 255, 255, 0.8);
        box-shadow: 0 0 1px 4px rgba(255, 255, 255, 0.28),
          0 0 2px 24px rgba(255, 255, 255, 0.15);
        position: relative;
        > i {
          position: absolute;
          bottom: 20%;
          left: -25%;
          width: 7px;
          height: 7px;
          background: #ffffff;
          border-radius: 50%;
          display: inline-block;
        }
        > i:before {
          content: "";
          display: inline-block;
          position: absolute;
          bottom: -17px;
          left: 110px;
          width: 7px;
          height: 7px;
          background: #ffffff;
          border-radius: 50%;
        }
      }
      > div:first-child:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 150%;
        height: 150%;
        border: 1px solid rgba(255, 255, 255, 0.4);
        border-radius: 50%;
      }
      > div:first-child:before {
        content: "";
        position: absolute;
        top: -24%;
        right: 15%;
        width: 7px;
        height: 7px;
        background: #ffffff;
        border-radius: 50%;
      }
      > div:last-child {
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
        > div:last-child {
          margin-top: 16px;
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: center;
          > div {
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;
            > div:first-child {
              font-size: 24px;
              font-weight: 500;
              margin-bottom: 2px;
            }
          }
        }
      }
    }
  }
  > div:last-child {
    width: 100%;
    height: calc(100% - 210px);
    overflow-y: scroll;
  }
  .d-header {
    padding-left: 10px;
    padding-top: 14px;
    font-size: 22px;
    font-weight: 400;
    color: #fff;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    i {
      color: #fff;
    }
  }
}
</style>